<div class="single-blog-post">
    <div class="entry-thumbnail" style="width: 300px">
        <a routerLink="/blog-details/{{blog.id}}"><img width="300" src="{{blog.image}}" alt="image"></a>
    </div>

    <div class="entry-post-content">
        <div class="entry-meta">
            <ul>
                <li><a routerLink="/blog-details/{{blog.id}}">{{blog.creator}}</a></li>
                <li>{{blog.date}}</li>
                <li>{{blog.time}}</li>
            </ul>
        </div>

        <h3><a routerLink="/blog-details/{{blog.id}}">{{blog.title}}</a></h3>
        <p>{{blog.description}}</p>
        <a routerLink="/blog-details/{{blog.id}}" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
    </div>
</div>
