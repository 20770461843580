import {Component, OnInit} from '@angular/core';
import {Blog, BlogService} from "../../../services/blog-service/BlogService";

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

    blogs: Blog[] = [];

    constructor(private blogService: BlogService) {
    }

    ngOnInit() {
        this.blogs = this.blogService.getFirstSixBlogs();
    }

}


