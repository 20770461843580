<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-110">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let blog of blogs">
                <app-blog-tile  [blog]="blog"></app-blog-tile>
            </div>

            <!--            <div class="col-lg-12 col-md-12">
                            <div class="pagination-area">
                                <a href="#" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                                <a href="#" class="page-numbers">1</a>
                                <span class="page-numbers current" aria-current="page">2</span>
                                <a href="#" class="page-numbers">3</a>
                                <a href="#" class="page-numbers">4</a>
                                <a href="#" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                            </div>
                        </div>-->
        </div>
    </div>
</section>
<!-- End Blog Area -->
