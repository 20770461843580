import {Injectable} from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class BlogService {


    private blogs: Blog[] = [
        {
            id: 3,
            title: "Release of Issue Notes for Jira",
            description: "Incrementti starts a journey with Atlassian apps",
            date: "February 22, 2023",
            creator: "Natali",
            image: "assets/img/blog/notes.jpg",
            time: "1 Min Read",
            body: [
                {
                    type: "header",
                    values: ["Issue Notes for Jira is available now!"]
                },
                {
                    type: "paragraph",
                    values: ["Issue Notes for Jira are advanced notes with a user-friendly interface.On the issue-level user can see modern-looking note cards with simple drag-n-drop reordering. It allows users to create unlimited notes for each issue. All notes by default are visible only to their author. If needed, users can share notes with other users or groups. Each note contains information about the creation date, author, and sharing info.There is also a project-level view, that allows users to see all their notes on the project. On this view, users can search for notes or use other management functionalities."]
                }
            ]
        },
        {
            id: 2,
            title: "Incrementti in 2022",
            description: "Short summary of the year",
            date: "December 30, 2022",
            creator: "Natali",
            image: "assets/img/blog/summary_2022.jpg",
            time: "2 Mins Read",
            body: [
                {
                    type: "header",
                    values: ["The end of 2022 is time for a summary"]
                },
                {
                    type: "paragraph",
                    values: ["A lot has happened in Incrementti, and here we will present the highlights of this year."]
                },
                {
                    type: "small-header",
                    values: ["1. Foundation"]
                },
                {
                    type: "paragraph",
                    values: ["In early 2022 Incrementti as a software house was founded. Initially, as an idea of creating high-quality software, Incrementti was born by a group of skilled developers who wanted to push the limits."]
                },
                {
                    type: "small-header",
                    values: ["2. Growth"]
                },
                {
                    type: "paragraph",
                    values: ["If we will need to describe the year in one word, it will be \"growth\". Thanks to our ambitions in designing software and the fantastic atmosphere in our team we dynamically grew up. "]
                },
                {
                    type: "small-header",
                    values: ["3. A new direction"]
                },
                {
                    type: "paragraph",
                    values: ["In November we decided to meet new challenges. Part of our team will create Jira add-ons. We have heads full of ideas, on how to make work in Jira easier, faster, and more efficient. "]
                },
                {
                    type: "small-header",
                    values: ["4. Great Expectations"]
                },
                {
                    type: "paragraph",
                    values: ["It's a title of an interesting book by Charles Dickens and at the same time our motto for 2023. We want to continue our growth, release a couple of products, and still be a nice company with an awesome atmosphere. "]
                }
            ]
        },
        {
            id: 1,
            title: "New chapter",
            description: "In Incrementti we decided to start creating Atlassians apps, for example for Jira",
            date: "November 2, 2022",
            creator: "Natali",
            image: "assets/img/blog/new_challenges.jpg",
            time: "1 Mins Read",
            body: [
                {
                    type: "header",
                    values: ["Step forward: Atlassian add-ons  "]
                },
                {
                    type: "paragraph",
                    values: ["Besides our normal work, we decided to take on a new challenge. We would like to create Atlassian add-ons. Sounds interesting? We will explain everything step by step :)  "]
                },
                {
                    type: "paragraph",
                    values: ["Atlassian is a software company that is responsible for creating a lot of great software. For example"]
                },
                {
                    type: "list",
                    values: ["Jira", "Confluence", "Bitbucket", "A lot of others"]
                },
                {
                    type: "paragraph",
                    values: ["A lot of project uses Jira to manage their work, create tasks, and monitor progress. We decided to start with Jira add-ons and create some applications, that will make the work in Jira easier, more efficient, and more intuitive. We have a lot of ideas. So be prepared for our new releases early in 2023 :) "]
                },
            ]
        }
    ];


    getFirstThreeBlogs() {
        return this.blogs.slice(0, 3);
    }

    getFirstSixBlogs() {
        return this.blogs.slice(0, 6);
    }

    getById(id: number) {
        return this.blogs.find(blog => blog.id === id);
    }
}


export interface Blog {
    id: number;
    title: string;
    description: string;
    date: string;
    creator: string;
    image: string;
    body: BlogPart[];
    time: string;
}

export interface BlogPart {
    type: 'paragraph' | 'quote' | 'header' | 'list' | 'small-header';
    values?: string[];
}
