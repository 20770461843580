<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="evolta-nav">
        <div class="{{styleContainer}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img width="300" src="assets/img/incrementti-logo.svg" alt="logo">
                </a>

                <button class="navbar-toggler mt-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>



                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">

                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/about" class="nav-link" routerLinkActive="active">Company</a>
                        </li>

                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/partners" class="nav-link" routerLinkActive="active">Partners</a>
                        </li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/blog" class="nav-link" routerLinkActive="active">Blog</a>
                        </li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/join" class="nav-link" routerLinkActive="active">Join us</a>
                        </li>
                        <li class="nav-item">
                            <a href="https://incrementti.atlassian.net/servicedesk/customer/portals" target="_blank">Get help</a>
                        </li>
                    </ul>
                    <div class="others-options" >
                        <a href="https://marketplace.atlassian.com/vendors/1223832/" target="_blank" class="btn btn-primary">Check our Jira apps</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
