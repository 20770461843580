import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PreloaderComponent} from './components/layout/preloader/preloader.component';
import {HeaderComponent} from './components/layout/header/header.component';
import {FooterComponent} from './components/layout/footer/footer.component';
import {HomeComponent} from './components/pages/home/home.component';
import {AboutComponent} from './components/pages/about/about.component';
import {TeamComponent} from './components/pages/team/team.component';
import {BlogComponent} from './components/pages/blog/blog/blog.component';
import {BlogDetailsComponent} from './components/pages/blog/blog-details/blog-details.component';
import {ServicesComponent} from './components/pages/services/services/services.component';
import {ServiceDetailsComponent} from './components/pages/services/service-details/service-details.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {ComingSoonComponent} from './components/pages/coming-soon/coming-soon.component';
import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {RegisterComponent} from './components/pages/register/register.component';
import {JoinComponent} from "./components/pages/join/join.component";
import {PartnersComponent} from "./components/pages/partners/partners.component";
import {ReactiveFormsModule} from "@angular/forms";
import {EmailService} from "./components/services/email-service/EmailService";
import {HttpClientModule} from "@angular/common/http";
import {BlogTileComponent} from "./components/pages/blog/blog-tile/blog-tile.component";
import {BlogService} from "./components/services/blog-service/BlogService";
import {ContactFormComponent} from "./components/pages/contact-form/contact-form.component";
import {PrivacyPolicyComponent} from "./components/pages/privacy-policy/privacy-policy.component";
import {EulaComponent} from "./components/pages/eula/eula.component";
import {PathLocationStrategy, LocationStrategy} from '@angular/common';

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        AboutComponent,
        TeamComponent,
        BlogComponent,
        BlogDetailsComponent,
        ServicesComponent,
        ServiceDetailsComponent,
        ContactComponent,
        ComingSoonComponent,
        NotFoundComponent,
        RegisterComponent,
        JoinComponent,
        PartnersComponent,
        BlogTileComponent,
        ContactFormComponent,
        PrivacyPolicyComponent,
        EulaComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    providers: [EmailService, BlogService, {provide: LocationStrategy, useClass: PathLocationStrategy}],
    bootstrap: [AppComponent]
})
export class AppModule {
}
