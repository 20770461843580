import { Component, OnInit } from '@angular/core';
import {Blog, BlogService} from "../../services/blog-service/BlogService";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    blogs : Blog[] = [];
  constructor(private blogService: BlogService) { }

  ngOnInit() {
      this.blogs = this.blogService.getFirstThreeBlogs();
  }

}
