<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Image Processing</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-110">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/3.png" alt="image">
            </div>

            <div class="services-details-desc">
                <h3>Incredible Infrastructure</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer.</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy.</p>

                <ul class="features-list">
                    <li><i class="bx bx-check-double"></i> Recommender Systems</li>
                    <li><i class="bx bx-check-double"></i> Demand Prediction</li>
                    <li><i class="bx bx-check-double"></i> Omnichannel Analytics</li>
                    <li><i class="bx bx-check-double"></i> Lead Generation</li>
                    <li><i class="bx bx-check-double"></i> Sales Prediction</li>
                </ul>
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Information Retrieval</h3>
                <p>Took a galley of type and scrambled it to make a type specimen book. survived not only five centuries, but also the leap into electronic remaining. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer when an unknown.</p>

                <div class="services-details-accordion">
                    <ul class="accordion">
                        <li class="accordion-item active">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Is Smart Lock required for instant apps?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services-details/1.png" alt="image">
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->
