<!-- Start Main Banner Area -->
<div class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <h1>Sail with us!</h1>
                            <p>We create high quality apps with attention to details</p>

                            <a href="https://marketplace.atlassian.com/vendors/1223832/" target="_blank" class="btn btn-primary">Check our Jira apps</a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <img src="assets/img/sailing-ship.svg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-shape"><img src="assets/img/white-shape.png" alt="image"></div>
    <div class="shape1"><img src="assets/img/shape/2.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape/3.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- Start Partner Area -->
<div class="partner-area">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#"><img src="../../../../assets/img/partner/angular_logo.svg" alt="image"></a>
            </div>

            <div class="single-partner-item">
                <a href="#"><img src="../../../../assets/img/partner/java_logo.svg" alt="image"></a>
            </div>

            <div class="single-partner-item">
                <a href="#"><img src="../../../../assets/img/partner/kotlin_logo.png" alt="image"></a>
            </div>

            <div class="single-partner-item">
                <a href="#"><img src="../../../../assets/img/partner/react_logo.png" alt="image"></a>
            </div>

            <div class="single-partner-item">
                <a href="#"><img src="../../../../assets/img/partner/scala_logo.png" alt="image"></a>
            </div>

            <div class="single-partner-item">
                <a href="#"><img src="../../../../assets/img/partner/heroku-logo.svg" alt="image"></a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Featured Solutions Area -->
<!--<section class="featured-solutions-area ptb-110">
    <div class="container">
        <div class="section-title">
            <h2>Our Atlassian Apps</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-featured-solutions">
                    <img src="assets/img/solutions/issue_notes_logo.svg" alt="image">

                    <h3>Issue Notes for Jira</h3>
                    <p>Create notes directly on issue, share with users and groups.</p>
                    <a href="#" class="read-more-btn">Try it free <i class="flaticon-add"></i></a>
                </div>
            </div>

        </div>
    </div>
</section>-->
<!-- End Featured Solutions Area -->


<div class="counter-area pb-100">
    <div class="container">
        <div class="counter-inner-box pt-100 pb-70">
            <div class="row justify-content-center">
<!--                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <div class="icon">
                            <i class='bx bx-check-shield'></i>
                        </div>
                        <h3>
                            <span class="odometer" data-count="2460">2460</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Completed project dolor sit amet.</p>
                    </div>
                </div>-->

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <div class="icon">
                            <i class='bx bx-layer'></i>
                        </div>
                        <h3>
                            <span class="odometer" data-count="832">10</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Technologies we use in our projects.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <div class="icon">
                            <i class='bx bx-user-circle'></i>
                        </div>
                        <h3>
                            <span class="odometer" data-count="1800">5</span>
                            <span class="sign-icon">+ years</span>
                        </h3>
                        <p>Average experience of our developers.</p>
                    </div>
                </div>



                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <div class="icon">
                            <i class='bx bx-buildings'></i>
                        </div>
                        <h3>
                            <span class="odometer" data-count="12">100% </span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Company growth of 2022. We have doubled in size.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Start Blog Area -->
<section class="blog-area ptb-110 bg-f9f9f9">
    <div class="container">
        <div class="section-title">
            <h2>Stay up to date with the latest news</h2>
        </div>

        <div class="row" >
            <div class="col-lg-4 col-md-6" *ngFor="let blog of blogs">
                <app-blog-tile  [blog]="blog" ></app-blog-tile>
            </div>

        </div>

    </div>
</section>
<!-- End Blog Area -->
