import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Blog, BlogService} from "../../../services/blog-service/BlogService";

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
    blog: Blog;

  constructor(private route: ActivatedRoute, private blogService: BlogService) { }

  ngOnInit() {
      let id = this.route.snapshot.paramMap.get('id');
      this.blog = this.blogService.getById(+id);
  }

}
