<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Privacy Policy</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<section class="about-area ptb-110">
    <div class="container">
        <div class="row-fluid-wrapper row-depth-1 row-number-1 dnd-section">
            <div class="row-fluid">
                <h2>Privacy policy</h2>
                <div class="mt-2">Your personal data is important for us. So we collect data and use it only when
                    necessary.
                </div>
                <div class="mt-2">Incrementti refer to NW Software. We offer Forge applications available through the
                    Atlassian
                    Marketplace page. We refer to all applications, along with our other services and websites,
                    collectively as the “Services”.
                </div>
                <h2 class="mt-5">Processing your personal data</h2>
                <h4 class="mt-4">Information from our website</h4>
                <div class="mt-2">When user enters our website, we collect basic information about user, such as ip
                    address, time of visit, visited pages, type of Internet browser and it's version, operating system
                    and version.
                </div>
                <h4 class="mt-4">Contact forms</h4>
                <div class="mt-2">We collect information You provide us in contact forms: name, email and other
                    information you provide
                </div>
                <h4 class="mt-4">Email contact</h4>
                <div class="mt-2">We collect information You provide us in an email.</div>
                <h4 class="mt-4">Support</h4>
                <div class="mt-2">We collect information when you contact us regarding the issue you are experiencing
                    with a Service. You may be asked to provide contact information, describe the problem you are
                    experiencing, and any other information, screenshots or something else that would be helpful in
                    resolving the problem.
                </div>
                <h4 class="mt-4">Data in our applications</h4>
                <div class="mt-2">All our applications are Forge apps. They are hosted on Atlassian infrastructure. We
                    do not collect any information when you use our apps.
                </div>

                <h4 class="mt-4">Atlassian Marketplace</h4>
                <div class="mt-2">Incrementti sells it's applications on Atlassian Marketplace. If customer decides to
                    use our applications under EULA, then Atlassian Marketplace provides us contact
                    information of the Licensee. Incrementti don’t store this information in our own servers. We only
                    access this information through Atlassian Marketplace user interface when required.
                </div>

                <h2 class="mt-5">How we process the data</h2>
                <h4 class="mt-4">To improve our apps</h4>
                <div class="mt-2">When you provide a feedback or information in any of possible ways, we always use it
                    to make our applications better, faster, useful, and secure.
                </div>
                <h4 class="mt-4">Support</h4>
                <div class="mt-2">We use provided information to resolve experienced issues, and response to requests,
                    analyze the work of our application and improve it.
                </div>
                <h4 class="mt-4">Communicate</h4>
                <div class="mt-2">We use contact information, to respond to your requests, comments, feedback. We send
                    emails to confirm purchase, send reminders about subscription expirations or to notify about
                    security issues.
                </div>
                <h4 class="mt-4">Legal basis and the purpose of personal data processing </h4>
                <div class="mt-2">We use contact information, to respond to your requests, comments, feedback. We send
                    emails to confirm purchase, send reminders about subscription expirations.
                </div>
                <div class="mt-2">Personal Data of our job Candidates are only processed in order to conduct a
                    recruitment process. Only for a specific position that the Candidate applied for, and only for the
                    period necessary to complete a recruitment process in a sense congruent with the legal regulations
                    (Art. 6.1.c of the GDPR).
                </div>


                <div class="mt-2"> Personal Data of our Employees is processed only for purposes consistent with legal
                    regulations and on their basis (Art. 6.1.c of the GDPR).
                </div>

                <div class="mt-2"> Personal Data of Users who ask questions through the contact form placed on
                    Incrementti website are processed on the basis of the given consent for Personal Data
                    processing (Art. 6.1.a of the GDPR). The Personal Data is stored only for the period necessary to
                    provide a response to the asked question. After this time, Personal Data of a person who contacted
                    us through the form is deleted.
                </div>
                <div class="mt-2">
                    Providing your personal data to us is voluntary, however, failure to do so
                    may prevent, depending on the specific case, the conclusion of a contract or receipt of answer to
                    questions included in contact form.
                </div>

                <h4 class="mt-4">What rights are provided to the persons whose data we process?</h4>
                <ul>
                    <li>Review, correct, fill person’s Personal Data</li>
                    <li>Limit or delete person’s Personal Data</li>
                    <li>to have a copy of your personal information</li>
                    <li>To transfer a person’s Personal Data to another Administrator</li>
                </ul>
                <div class="mt-2">
                    If you have consented to our use of information about you for a specific purpose, you have the right
                    to change your mind at any time, but this will not affect any processing that has already taken
                    place. Where we are using your information because we have a legitimate interest to do so, you have
                    the right to object to that use though, in some cases, this may mean no longer using the Services.
                </div>

                <div class="mt-2">
                    You can exercise the rights described above at any time, filling a request via e-mail at
                    <b>contact@incrementti.com</b> . If necessary, we will ask you to provide us with some additional
                    information allowing for authentication in order to make sure that you are authorized to receive the
                    requested information.
                </div>
            </div>
        </div>
    </div>
</section>


