<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-110">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>Everything is possible</h2>
                    <p>This is where the passion for creating beautiful, functional and useful applications meets an innovative team and something special is created.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/people.svg" alt="image">
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Our History</h3>
                        <p>Incrementti was founded by a team of high skilled developers in order to get out of comfort zone and go beyond the limits.</p>

                        <i class="flaticon-house"></i>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Our Mission</h3>
                        <p>Therefore whatever you desire for men to do to you, you shall also do to them.</p>
                        <p>Matthew 7:12</p>

                        <i class="flaticon-insurance"></i>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>What we do</h3>
                        <p>Work with passion.</p>
                        <p>We create innovative applications, modern websites, and much more.</p>

                        <i class="flaticon-box"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->


