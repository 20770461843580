<div class="contact-form">
    <form id="contactForm" [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="form-group mb-3">
                    <label for="Name">Name</label>
                    <input type="text" name="Name" id="Name" class="form-control" formControlName="Name" required>
                    <div *ngIf="(formControl.Name.touched || submitted) && formControl.Name.errors?.required" class="help-block">Please enter your name</div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="form-group mb-3">
                    <label for="EmailAddress">Email</label>
                    <input type="email" name="EmailAddress" id="EmailAddress" class="form-control"
                           formControlName="EmailAddress" required email>
                    <div *ngIf="(formControl.EmailAddress.touched || submitted) && formControl.EmailAddress.errors?.required" class="help-block">Please enter your email</div>
                    <div *ngIf="(formControl.EmailAddress.touched || submitted) && formControl.EmailAddress.errors?.email" class="help-block">Please enter valid email</div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="form-group mb-3">
                    <label for="Message">{{messageLabel}}</label>
                    <textarea name="Message" class="form-control" id="Message" formControlName="Message" cols="30"
                              rows="5" required data-error="Write your message"></textarea>
                    <div *ngIf="(formControl.Message.touched || submitted) && formControl.Message.errors?.required" class="help-block">Please enter yours message</div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <button type="submit" class="btn btn-primary">Send Message</button>
                <div id="msgSubmit" class="h3 text-center hidden"></div>
                <div class="clearfix"></div>
            </div>
        </div>
    </form>
</div>

