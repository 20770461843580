<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a href="#"><img src="assets/img/incrementti-logo.svg" width="270" alt="image"></a>
                        <p>This is where the passion for creating beautiful, functional and useful applications meets an
                            innovative team and something special is created.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Company</h3>

                    <ul class="footer-services-list">
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/join">Careers</a></li>
                        <li><a routerLink="/partners">Partners</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>

                    <ul class="quick-links-list">
                        <li><a routerLink="/blog">Blog</a></li>
                        <li><a routerLink="/">Our Jira apps</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Get help</h3>

                    <ul class="quick-links-list">
                        <li><a target="_blank" href="https://docs.incrementti.com">Documentation</a></li>
                        <li><a target="_blank" href="https://incrementti.atlassian.net/servicedesk/customer/portals">Support portal</a></li>
                        <li><a target="_blank" href="https://incrementti.atlassian.net/servicedesk/customer/portal/3/group/19/create/49">Report a problem</a></li>
                        <li><a target="_blank" href="https://incrementti.atlassian.net/servicedesk/customer/portal/3/group/19/create/54">Suggest new feature</a></li>
                    </ul>
                </div>
            </div>

        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>&copy; Copyright {{currentYear}} Incrementti. All Rights Reserved.</p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/privacy">Privacy Policy</a></li>
                        <li><a routerLink="/eula">EULA</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="circle-map"><img src="assets/img/circle-map.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
<!-- End Footer Area -->
<div class="go-top"><i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i></div>
