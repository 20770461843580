<app-preloader></app-preloader>
<ng-container *ngIf="!(location == '/coming-soon' || location == '/login' || location == '/register')">
    <app-header></app-header>
        <router-outlet></router-outlet>
    <app-footer></app-footer>
</ng-container>

<ng-container *ngIf="(location == '/coming-soon' || location == '/login' || location == '/register')">
    <router-outlet></router-outlet>
</ng-container>
