import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EmailService} from "../../services/email-service/EmailService";

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

    @Input() messageLabel: string;
    submitted = false;
    FormData: FormGroup;

    constructor(private builder: FormBuilder, private emailService: EmailService) {
    }

    ngOnInit() {
        this.FormData = this.builder.group({
            Name: new FormControl('', [Validators.required]),
            EmailAddress: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
            Message: new FormControl('', [Validators.required])
        })
    }

    get formControl() {
        return this.FormData.controls;
    }


    onSubmit(FormData) {
        this.submitted = true;
        if (this.FormData.valid) {
            this.emailService.PostMessage(FormData)
                .subscribe(response => {
                    location.href = 'https://mailthis.to/confirm'
                    console.log(response)
                }, error => {
                    console.warn(error.responseText)
                    console.log({error})
                })
        }
    }
}
