<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>End-User License Agreement</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>EULA</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<section class="about-area ptb-110">
    <div class="container">
        <div class="row-fluid-wrapper row-depth-1 row-number-1 dnd-section">
            <div class="row-fluid">

                <div class="mt-2">
                    PLEASE READ THIS END USER LICENSE AGREEMENT (“AGREEMENT”) CAREFULLY BEFORE USING THE INCREMENTTI
                    SOFTWARE (“SOFTWARE”). THIS EULA IS AN AGREEMENT BETWEEN YOU (“LICENSEE”) AND INCREMENTTI
                    (“LICENSOR” OR
                    “INCREMENTTI”). BY DOWNLOADING, INSTALLING, COPYING, ACCESSING OR OTHERWISE USING THE SOFTWARE
                    BUTTON
                    LICENSEE, OR THE PERSON OR ENTITY ON BEHALF OF WHICH THE INDIVIDUAL INSTALLING THE SOFTWARE IS
                    ACTING, UNCONDITIONALLY AGREES TO THE TERMS OF THIS AGREEMENT. CLICK ON THE “ACCEPT” BUTTON TO
                    ACCEPT ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT WANT TO BE BOUND BY THIS
                    AGREEMENT YOU SHALL NOT BE ABLE NOR ENTITLED TO DOWNLOAD, INSTALL, COPY, ACCESS OR OTHERWISE USE THE
                    SOFTWARE.
                </div>


                <!-- 1. Definitions -->
                <h4 class="mt-4">1. Definitions</h4>
                <div class="mt-2">
                    <div class="mt-2">1.1 “Affiliate” means an entity controlled by or under common control with the
                        subject entity.
                        “Control,” for purposes of this definition, means direct or indirect ownership or control of
                        more than 50% of the voting interests of the subject entity.
                    </div>
                    <div class="mt-2">1.2 “Atlassian Marketplace” means the online marketplace that provides
                        downloadable, accessible, and cloud-based application Products available at:
                        https://marketplace.atlassian.com or its successor.
                    </div>
                    <div class="mt-2">1.3 "Authorized User" means a unique user who is given access to the Software
                        under a User License.
                    </div>
                    <div class="mt-2">1.4 “Reseller” means a third party licensing or otherwise distributing the
                        Products, who are authorized by Incrementti to do so.
                    </div>
                    <div class="mt-2">1.5 "Commercial License" means a license to use Incrementti Software, that is
                        obtained or renewed by the Licensee by paying Fees.
                    </div>
                    <div class="mt-2">1.6 "Fees" mean all fees and expenses payable by the Licensee to Incrementti for
                        licaense to use Software, including Maintenance. Fees also include any applicable additional
                        fees.
                    </div>
                    <div class="mt-2">1.7 “Maintenance” means: Product Updates, required Atlassian platform
                        compatibility
                        updates, and the online support for technical issues related to the use of Software
                    </div>
                    <div class="mt-2">1.8 “Product Update(s)” is a part of Maintenance and means any bug fixes, patches,
                        modifications, major or minor releases, updates,
                        upgrades, changes, enhancements for Software that may
                        be made generally available by Incrementti.
                    </div>
                    <div class="mt-2">1.9 "Non-Commercial License" means a free license provided by Incrementti under
                        additional restrictions, specified in the license description on Incrementti web site.
                    </div>
                    <div class="mt-2">1.10 "Release Date" means a specific date associated with a specific version of
                        Software by
                        Incrementti as the date of release of that version to the public.
                    </div>
                    <div class="mt-2">1.11 "Software" means all add-ons placed on Atlassian Marketplace under
                        Incrementti account - computer software, developed and distributed by Incrementti.
                    </div>
                    <div class="mt-2">1.12 "Evaluation version" means a specific version of Software that is granted
                        under Evaluation License (section 2.3)
                    </div>
                    <div class="mt-2">1.13 "User License" means a license granted by Incrementti to use Software.
                    </div>
                </div>
                <!-- End: 1. Definitions -->


                <!-- 2. Grant of License -->
                <h4 class="mt-4">2. Grant of License</h4>
                <div class="mt-4">2.1 Non-Commercial License</div>
                <div class="mt-2">2.1.1 Incrementti grants the Licensee the
                    right to use the Incrementti Software, subject to the License Terms in section 2.4
                </div>
                <div class="mt-2">2.1.2 Licensee can install, use the Software only by Authorized User. If the License
                    has an expiration date, Licensee and Authorized Users must stop using the Software after the
                    expiration date.
                </div>
                <div class="mt-4">2.2 Commercial License</div>
                <div class="mt-2">2.2.1 Incrementti grants the Licensee the right to use the Incrementti Software,
                    subject to the General License Terms in section 2.4
                </div>
                <div class="mt-2">2.2.2 Licensee can install any versions of Software that has Release Date prior to the
                    Maintenance Expiration Date on a single server.
                </div>
                <div class="mt-2">2.2.3 Licensee can use the Software only with the number of Authorized Users
                    concurrently accessing and using the Software equal to the number of User Licenses purchased.
                    Additional User Licenses can be purchased by the Licensee on payment of the appropriate Fees to
                    Incrementti or the Reseller.
                </div>
                <div class="mt-2">2.2.4 All Fees should be paid by the Licensee within the prescribed period. Failure to
                    pay by the Licensee within this period results in the immediate revoking of the License.
                </div>
                <div class="mt-4">2.3 Evaluation License</div>
                <div class="mt-2">2.3.1 Under Evaluation License Incrementti grants the temporary right to use Software.
                    Evaluation License has an expiration date. Evaluation License has a length up to 6 months.
                </div>
                <div class="mt-4">2.4 License Terms</div>
                <div class="mt-2">2.4.1 Licensee is not allowed to pass on, sell, or in any other way give access to
                    Software to not Authorized Users. Licensee is not allowed to decompile the code, modify it or use in
                    any other way.
                </div>
                <div class="mt-2">2.4.2 Rights granted by License are not sublicensable, are not exclusive,
                    are not transferable, and are limited. Rights are worldwide.
                </div>
                <div class="mt-2">2.4.3 Licensee can install/use Incrementti Software only on the infrastructure that is
                    controlled by the Licensee.
                </div>
                <!-- End 2. -->

                <!-- 3. -->
                <h4 class="mt-4">3. Reservation of Rights and Ownership </h4>
                <div class="mt-4">3.1 Licensor reserves all rights not expressly granted to the Licensee in this
                    Agreement.
                    The Software and all copies thereof are protected by copyright and other intellectual property laws
                    and treaties. Licensor or its relevant third parties own the title, copyright, and all other
                    intellectual property rights in the Software and all subsequent copies of the Software. The Software
                    is licensed, not sold and the Licensee does not acquire any rights of ownership in the Software
                </div>
                <!-- End 4. -->

                <h4 class="mt-4">4. Exclusion of Warranties</h4>
                <div class="mt-4">4.1 The Software is provided on an “as is” and “as available” basis without warranty,
                    express or implied, of any kind or nature, including, but not limited to, any warranties of
                    performance or merchantability or fitness for a particular purpose, including without limitation
                    that.
                </div>
                <div class="mt-2">4.2 Incrementti does not warranty that the Software will be error-free, complete, or
                    correct. The Licensor provides Evaluation License of the Software so that users can assess the
                    Software.
                </div>

                <h4 class="mt-4">5. Limitation of Liability</h4>
                <div class="mt-4">5.1 To the maximum extent permitted under applicable law, Incrementti excludes its
                    liability for damages of any kind or nature, including without limitation any compensatory,
                    incidental, direct, indirect, special, punitive or consequential damages (including (without
                    limitation) loss of use, loss or corruption of data, loss of income or profit, loss of or damage to
                    property, claims of third parties, reputational harm, opportunity loss, loss of contracts or
                    customers, loss of goodwill, loss of anticipated savings, recoupment of any investment made, the
                    cost of procuring replacement goods or services, and other losses including attorneys’ fees) in
                    connection with (a claim relating to) this Agreement, including (without limitation) claims
                    resulting from the (non-)performance of the Software. The foregoing exclusion shall equally apply to
                    the benefit of Incrementti’s and its affiliates’ employees, agents, suppliers and contractors.
                </div>
                <div class="mt-2">5.2 In the event that liability is nevertheless imposed on Incrementti, its
                    affiliates, their employees,
                    agents, suppliers or contractors, the liability shall not exceed the amount of the Fees (excl.
                    taxes) paid for the Software, if any. In no event shall Incrementti have any obligations,
                    responsibilities or liability whatsoever with respect to a backup copy of the Software made by the
                    Licensee. Third party material shall be exclusively governed by the applicable third party terms and
                    conditions.
                </div>

                <h4 class="mt-4">6. Termination</h4>
                <div class="mt-4">6.1 Incrementti may terminate upon notice this Agreement in case if customer fail to
                    comply with any term.
                </div>
                <div class="mt-2">6.2 For Evaluation License and Non-Commercial License , either party may terminate
                    this Agreement for any reason or no reason whatsoever by providing notice to other party effective
                    upon notice.
                </div>
                <div class="mt-2">6.3 The Evaluation License will expire after the expiration date. Incrementti may
                    extend the Evaluation License in agreement with Authorized User.
                </div>
                <div class="mt-2">6.4 Regardless of the reason for termination, the Fees paid are non-refundable.</div>
                <div class="mt-2">6.5 In case of decommissioning of the Software results in termination of Agreement.
                </div>
                <div class="mt-2">6.6 After termination of a license granted under this Agreement, the Licensee must at
                    its own cost cease permitting access to and ensure that all Authorized Users stop using the Software
                    immediately.
                </div>
                <div class="mt-2">6.7 Sections 2.4, 3, 4, 5, 6.1, 8, 9, 10 and 11 shall survive any termination of this
                    Agreement.
                </div>

                <h4 class="mt-4">7. Fees and Taxes</h4>
                <div class="mt-4">7.1 Licensee shall pay to Incrementti or it's Reseller a license fee in exchange for
                    the Commercial License to use Software granted to Licensee under this Agreement.
                </div>
                <div class="mt-2">7.2 Failure to pay Fees by their due date may result, without limitation, in the
                    immediate termination of the licenses and rights granted to you under this Agreement.
                </div>
                <h4 class="mt-4">8. Marketing</h4>
                <div class="mt-4">8.1 Licensee agrees to be identified as a customer of Incrementti and that Incrementti
                    may refer to Licensee by name, trade name and trademark, if applicable, and may briefly describe
                    Licensee's business in Licensor's marketing materials, on Licensor's web site, in public or legal
                    documents.
                </div>
                <div class="mt-2">8.2 Incrementti can waive from marketing right described in section 8.1 upon
                    submitting a written request via email to <b>contact@incrementti.com</b>, requesting to be excluded from
                    promotional materials.
                </div>

                <h4 class="mt-4">9. Amendment</h4>
                <div class="mt-4">9.1 This Agreement may not be amended except with the written agreement of Licensor
                    whose consent may be withheld in its complete discretion without any requirement to provide reasons.
                </div>
                <h4 class="mt-4">10. Agreement </h4>
                <div class="mt-4">10.1 The Licensee may not assign this Agreement, in whole or in part, without the
                    prior written consent of the Licensor.
                </div>

                <h4 class="mt-4">11. Miscellaneous</h4>
                <div class="mt-4">11.1 This Agreement constitutes the entire statement of the agreement between the
                    parties on the subject matter, and merges and supersedes all other or prior understandings,
                    agreements and arrangements.
                </div>
                <div class="mt-2">11.2 If any provision of this EULA is held to be unenforceable for any reason, such
                    provision shall be reformed only to the extent necessary to make it enforceable, and the remaining
                    provisions of this EULA shall remain in full force and not be affected.
                </div>

                <h4 class="mt-4">12. Contact information</h4>
                <div class="mt-4">In case of any questions about this Agreement, or in any other reason please email
                    <b>contact@incrementti.com</b>.
                </div>

            </div>
        </div>
    </div>
</section>


