<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-110">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>We'd Love to Hear from You!</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-4 col-md-4">
                <div class="contact-image">
                    <img src="assets/img/contuct_us.svg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <app-contact-form messageLabel="Message"></app-contact-form>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>

                    <h3>Email</h3>
                    <p>contact@incrementti.com</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>

                    <h3>Address</h3>
                    <p>Headquarter, ul. Ratajow 52, 52-230 Wroclaw, Poland</p>
                </div>
            </div>

<!--            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>

                    <h3>Call Here</h3>
                    <p><a href="tel:1234567890">+123 456 7890</a></p>
                    <p><a href="tel:2414524526">+241 452 4526</a></p>
                </div>
            </div>-->
        </div>
    </div>
</section>
<!-- End Contact Area -->
