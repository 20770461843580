import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})

export class EmailService {
    private url = "https://mailthis.to/incrementti@gmail.com"



    constructor(private http: HttpClient) {
    }

    PostMessage(input: any) {
        return this.http.post(this.url, input, { responseType: 'text' })
            .pipe(
                map(
                    (response) => {
                        if (response) {
                            return response;
                        }else{
                            return null;
                        }
                    },
                    (error: any) => {
                        return error;
                    }
                )
            )
    }
}


