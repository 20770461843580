import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/pages/home/home.component';
import {AboutComponent} from './components/pages/about/about.component';
import {BlogComponent} from './components/pages/blog/blog/blog.component';
import {BlogDetailsComponent} from './components/pages/blog/blog-details/blog-details.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {JoinComponent} from "./components/pages/join/join.component";
import {PartnersComponent} from "./components/pages/partners/partners.component";
import {PrivacyPolicyComponent} from "./components/pages/privacy-policy/privacy-policy.component";
import {EulaComponent} from "./components/pages/eula/eula.component";


const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details/:id', component: BlogDetailsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'join', component: JoinComponent},
    {path: 'partners', component: PartnersComponent},
    {path: 'privacy', component: PrivacyPolicyComponent},
    {path: 'eula', component: EulaComponent},

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
