<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Join us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Join us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-110">
    <div class="container">
        <div class="section-title">
            <h2>We'd love You to be part of Our team!</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-4 col-md-4">
                <div class="contact-image">
                    <img src="assets/img/join.svg" alt="image">
                </div>
            </div>

            <div class="col-lg-8 col-md-8">
                <app-contact-form messageLabel="What do you want to do at Incrementti and why?"></app-contact-form>
            </div>
        </div>

    </div>
</section>
<!-- End Contact Area -->
