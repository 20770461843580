import {Component, Input, OnInit} from '@angular/core';
import {Blog} from "../../../services/blog-service/BlogService";

@Component({
    selector: 'app-blog-tile',
    templateUrl: './blog-tile.component.html',
    styleUrls: ['./blog-tile.component.scss']
})
export class BlogTileComponent implements OnInit {

    @Input("blog") blog: Blog;

    constructor() {
    }


    ngOnInit() {
    }

}
