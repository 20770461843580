<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{blog.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-110">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details">
                    <div class="article-image">
                        <img src="{{blog.image}}" alt="image">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><i class="far fa-user-circle"></i> By: <a href="#">{{blog.creator}}</a></li>

                                <li><i class="far fa-calendar-alt"></i> {{blog.date}}</li>

                                <li><i class="far fa-clock"></i>{{blog.time}}</li>

                            </ul>
                        </div>
                        <div *ngFor="let blogPart of blog.body">

                            <p class="mt-1" *ngIf="blogPart.type == 'paragraph'">{{blogPart.values[0]}}</p>

                            <blockquote *ngIf="blogPart.type == 'quote'" class="wp-block-quote">
                                <p>{{blogPart.values[0]}}</p>

                                <cite>{{blogPart.values[1]}}</cite>
                            </blockquote>

                            <h3 class="mt-1" *ngIf="blogPart.type == 'header'">{{blogPart.values[0]}}</h3>
                            <h4 class="mt-2" *ngIf="blogPart.type == 'small-header'">{{blogPart.values[0]}}</h4>

                            <ul *ngIf="blogPart.type == 'list'" class="article-features-list">
                                <li *ngFor="let listValue of blogPart.values">{{listValue}}</li>
                            </ul>

                        </div>


                        <!--                        <ul class="wp-block-gallery columns-3">
                                                    <li class="blocks-gallery-item">
                                                        <figure>
                                                            <img src="assets/img/blog/8.jpg" alt="image">
                                                        </figure>
                                                    </li>

                                                    <li class="blocks-gallery-item">
                                                        <figure>
                                                            <img src="assets/img/blog/3.jpg" alt="image">
                                                        </figure>
                                                    </li>

                                                    <li class="blocks-gallery-item">
                                                        <figure>
                                                            <img src="assets/img/blog/4.jpg" alt="image">
                                                        </figure>
                                                    </li>
                                                </ul>-->

                    </div>

                </div>

            </div>

        </div>
    </div>
</section>
<!-- End Blog Details Area -->
