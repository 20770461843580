<!-- Start Coming Soon Area -->
<section class="coming-soon-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="coming-soon-time">
                    <img src="assets/img/coming-soon-bg.jpg" alt="image">

                    <div id="timer">
                        <div id="days">{{days}} <span>Days</span></div>
                        <div id="hours">{{hours}} <span>Hours</span></div>
                        <div id="minutes">{{minutes}} <span>Minutes</span></div>
                        <div id="seconds">{{seconds}} <span>Seconds</span></div>
                    </div>

                    <div class="bg-shape1"><img src="assets/img/shape/4.svg" alt="image"></div>
                    <div class="bg-shape2"><img src="assets/img/shape/4.svg" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="coming-soon-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="logo">
                                <a routerLink="/">
                                    <img src="assets/img/black-logo.png" alt="logo">
                                </a>
                            </div>

                            <h3>Our website is Coming Soon, follow us for update now!</h3>

                            <form>
                                <div class="form-group mb-3">
                                    <input type="text" class="form-control" name="name" id="name" placeholder="Your Name">
                                </div>

                                <div class="form-group mb-3">
                                    <input type="email" class="form-control" name="email" id="email" placeholder="Your Email Address">
                                </div>

                                <button type="submit" class="btn btn-primary">Subscribe</button>

                                <p>And don’t worry, we hate spam too! You can unsubcribe at anytime.</p>
                            </form>

                            <div class="social">
                                <ul>
                                    <li><a href="#" target="_blank" class="twitter"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="#" target="_blank" class="youtube"><i class="fab fa-youtube"></i></a></li>
                                    <li><a href="#" target="_blank" class="facebook"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#" target="_blank" class="linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="#" target="_blank" class="instagram"><i class="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Coming Soon Area -->
